import { CSSProperties, ReactNode, ElementType } from 'react';
import classNames from 'classnames';

import Typography, { Display } from '@components/atoms/Typography';
import createStyleVariables from 'utils/createStyleVariables';

import classes from './Paragraph.module.scss';

type Props = {
  className?: string;
  color?: string;
  align?: CSSProperties['textAlign'];
  display?: Display;
  renderAs?: ElementType;
  children: ReactNode;
};

const Paragraph = ({ className, color, align, display = 'block', renderAs, children }: Props): JSX.Element => (
  <Typography
    variant="body1"
    display={display}
    className={classNames(classes.paragraph, className)}
    align={align}
    renderAs={renderAs}
    style={createStyleVariables({ color })}
  >
    {children}
  </Typography>
);

export default Paragraph;
